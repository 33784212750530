import React from "react";
import ReactDOM from "react-dom";
import "./assets/style.less";
import ReservationContainer from "@itt/otr";
import gtag from "./gtagWrapper";

class OtrLoader {
	constructor(configurationId, steps, initialData, trackingId) {
		this.configurationId = configurationId;
		this.steps = steps;
		this.initialData = initialData;
		this.trackingId = trackingId;
		gtag("js", new Date());
		gtag("config", trackingId);
	}

	render() {
		ReactDOM.render(
			<ReservationContainer
				apiUrl={process.env.REACT_APP_API_URL}
				imageUrl={process.env.REACT_APP_IMAGE_URL}
				configId={this.configurationId}
				steps={this.steps}
				initialData={this.initialData}
			/>,
			document.getElementById("root")
		);
	}
}

document.OtrLoader = OtrLoader;
